<template>
  <div class="auth">
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="auth-block">
          <h1 class="auth-title">Maviepro</h1>
          <div class="auth-form">
            <p>{{ message }}</p>
            <b-button tag="router-link" class="mt-5"
              type="is-primary" native-type="submit" expanded
              to="/login"
            >
                Se connecter
            </b-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { http } from '@/services/api.service'

export default {
  name: 'CreatePassword',
  data () {
    return {
      message: 'Vérification de votre jeton en cours... Veuillez patienter...'
    }
  },
  mounted () {
    this.validate()
  },
  methods: {
    validate () {
      http.post('/auth/verify', { token: this.$route.params.token }).then(() => {
        this.message = 'Votre adresse mail a bien été vérifiée, vous pouvez vous connecter à l\'application'
      }).catch((err) => {
        this.message = err.response.data
      })
    }
  }
}
</script>

<style lang="scss">
body {
  background: whitesmoke;
}
.hero-body {
  justify-content: center;
  flex-direction: column;
}
.login {
  background: white;
  padding: 25px;
  box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02);
}
.login-hr {
  border-bottom: 1px solid black;
}
</style>
